import {
    GET_ALL_TEMPLATE_ACTION,
    SUCCESSFULLY_GET_ALL_TEMPLATE,
    CREATE_TEMPLATE_ACTION,
    SUCCESSFULLY_CREATE_TEMPLATE,
    UPDATE_TEMPLATE_ACTION,
    SUCCESSFULLY_UPDATE_TEMPLATE,
  } from "../actionTypes/templateActionTypes";
  
  export const getAllTemplateAction = (details) => {
    console.log(details,"FFFFF");
    
    return { type: GET_ALL_TEMPLATE_ACTION, payload: details };
  };
  
  export const getAllTemplateData = (allTemplate) => {
    console.log(allTemplate,"allTemplate");
    
    return { type: SUCCESSFULLY_GET_ALL_TEMPLATE, payload: allTemplate };
  };
  // subscription management
  
  export const createTemplateAction = (details) => {
    console.log(details,"details");
    
    return { type: CREATE_TEMPLATE_ACTION, payload: details };
  };
  
  export const createTemplateData = (allViewJson) => {
    return { type: SUCCESSFULLY_CREATE_TEMPLATE, payload: allViewJson };
  };
  
  // update subscription config
  
  export const updateTemplateAction = (data)=>{
    return{type:UPDATE_TEMPLATE_ACTION,payload:data}
  }   
  
  export const successfullyUpdatedTemplateDetails = (data)=>{
    return{type:SUCCESSFULLY_UPDATE_TEMPLATE,payload:data}
  }
  