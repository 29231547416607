// React utils
import { useEffect, useRef, useState } from "react";

// mui components
import { Paper, List, ListItem, ListItemText, Box } from "@mui/material";

//Icons
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";

const defaultStyles = (disabled) => ({
  cursor: disabled ? "not-allowed" : "pointer",
});

const Autocomplete = ({
  options,
  getOptionLabel,
  disabled,
  renderInput,
  onChange,
  optionListStyle,
  defaultValue,
  onReset,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const boxRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(()=>{
    if(!inputValue && defaultValue){
      setInputValue(defaultValue)
    }

  },[defaultValue])

  const handleFilter = (value, source = null) => {
    if (value?.length || 0 > 0) {
      const filteredOptions = options.filter((option,index) => {
        const label = getOptionLabel(option,index);
        return label.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredOptions(filteredOptions);
      (!filteredOptions.length > 0)? setShowSuggestions(false):setShowSuggestions(true);  
    } else {
      setFilteredOptions(options);
      setShowSuggestions(true);
    }
  };
  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    handleFilter(value);
  };

  const handleSuggestionClick = (event, option, index) => {
    onChange(event, option,index);
    const label = getOptionLabel(option, index);
    setInputValue(label);
    setShowSuggestions(false);
  };
  const handleFocus = () => {
    handleFilter(inputValue);
  };
  const handleArrowClick = () => {
    // if (!filteredOptions.length && !inputValue?.length) {
      setFilteredOptions(options);
    // }
    setShowSuggestions((show) => !show);
  };
  useEffect(() => {
    // responds for outside clicks
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    // responds for keys like TAB,ESCAPE
    const handleKeyboardEvent = (event) => {
      if (event.key === "Tab") {
        inputRef.current.querySelector("input").blur();
        boxRef.current.blur();
        setShowSuggestions(false);
      } else if (
        inputRef.current &&
        boxRef.current &&
        (boxRef.current.id === document.activeElement.id ||
          inputRef.current.querySelector("input").id ===
            document.activeElement.id) &&
        event.key === "Escape"
      ) {
        inputRef.current.querySelector("input").blur();
        boxRef.current.blur();
        setShowSuggestions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    boxRef?.current?.addEventListener("keydown", handleKeyboardEvent);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      boxRef?.current?.removeEventListener("keydown", handleKeyboardEvent);
    };
  }, [boxRef.current, inputRef.current]);

  useEffect(() => {
    const suggestionElement = boxRef?.current?.querySelector(
      "#VIVRE_Autocompletion_Suggestions",
    );
    const currrentPage = document.querySelector(".makeStyles-mainPanelOp-3");
    const adjustSuggestionsPosition = (event) => {
  
      // 785px(viewport) - 80px(footer) -5px(safeside) ==> 700px
      
      if (inputRef.current && suggestionElement) {
        const inputRect = inputRef.current.getBoundingClientRect();
        const suggestionRect = suggestionElement.getBoundingClientRect();
        let _footerHeight = 80 
        let _offset = suggestionRect.height
        const viewportHeight = window.innerHeight;
        if(viewportHeight-(inputRect.bottom+_footerHeight+_offset)<=0){  
            suggestionElement.style.bottom = "100%"

            suggestionElement.style.marginTop = null
            suggestionElement.style.marginBottom = "8px"
        }else{
            suggestionElement.style.bottom = null

            suggestionElement.style.marginBottom = null
            suggestionElement.style.marginTop = "7px"

        }
      }
    };
    currrentPage?.addEventListener("scroll", adjustSuggestionsPosition);
    return () => {
      currrentPage?.removeEventListener("scroll", adjustSuggestionsPosition);
    };
  }, [boxRef?.current,showSuggestions]);

  return (
    <div
      id={"VIVRE_Autocompletion_container"}
      ref={boxRef}
      style={{ position: "relative" }}
      tabIndex={1}
    >
      <Box>
        <div style={{ position: "relative" }}>
          {renderInput({
            onChange: handleChange,
            onFocus: handleFocus,
            value: inputValue,
            disabled,
            ref: inputRef,
            id: "VIVRE_Autocompletion_input",
            autoComplete:"off"
          })}
          {
           
            <Box  sx={{

              position: "absolute",
              right: "10px",
              top: "35%",
              transform: "-translateY(100%)",
              display:"flex",
              alignItems:"center"
            }}>
             {String(inputValue)?.length>0 &&   <ClearIcon
            onClick={() => {
              setInputValue("");
              setShowSuggestions(true);
              setFilteredOptions(options);
              if(onReset){
                onReset()
              }
            }}
            sx={{ cursor: "pointer",}}
           
            fontSize="small"
          />
}
          <ArrowDropDownIcon
              sx={{ cursor: "pointer"}}
              style={{transform: showSuggestions? "rotate(0.5turn)":"rotate(0turn)"}}
              onClick={()=>{
                if (!disabled){
                  handleArrowClick()
                }
              }}
              
              fontSize="medium"
          />

            </Box>
           
          }    
  
        </div>

        {showSuggestions && filteredOptions.length>0 &&(
          <Paper  id={"VIVRE_Autocompletion_Suggestions"} sx={{marginBottom:"8px",marginTop:"7px", position:"absolute",width:"412px",zIndex:10,...optionListStyle}}>
            <List>
              {filteredOptions.map((option, index) => (
                <ListItem
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "lightsteelblue",
                    },
                    px: 2,
                    py: 0.2,
                    ...{
                      backgroundColor:
                        getOptionLabel(option,index) === inputValue
                          ? "lightsteelblue"
                          : {},
                    },
                  }}
                  key={index}
                  onClick={(event) => handleSuggestionClick(event, option,index)}
                >
                  <ListItemText primary={getOptionLabel(option,index)} />
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
      </Box>
    </div>
  );
};

export default Autocomplete;
