// StyledSelect.js
import React from 'react';
import { FormControl, Select, MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/system';

const CustomFormControl = styled(FormControl)({
  width: '100%',
  margin: '8px 0',
  position: 'relative',
});

const CustomSelect = styled(Select)({
  height: '40px', 
  minWidth: '60px',
  maxWidth: '140px',
  backgroundColor: '#f0f0f0',
  borderRadius: '8px',
  padding: '10px',
  '&:hover': {
    backgroundColor: '#e0e0e0',
  },
  '& .MuiSelect-select': {
    paddingLeft: '16px',
    color: '#333',
  },
  '& .MuiSelect-icon': {
    right: '10px',
  },
});

const StyledSelect = ({ selectedType, setSelectedType, options ,placeholder}) => (
  <CustomFormControl>
    <CustomSelect
      value={selectedType}
      onChange={(e) => setSelectedType(e.target.value)}
      displayEmpty
      renderValue={(value) => (value ? value : <Typography style={{ color: '#aaa' }}>{placeholder}</Typography>)} // Show placeholder if no value
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </CustomSelect>
  </CustomFormControl>
);

export default StyledSelect;
