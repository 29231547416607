import React, { useState } from 'react';
import { Button, Menu, MenuItem, Divider, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TvIcon from '@mui/icons-material/Tv';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'; // Example icon

const CustomDropdown = ({ items, onDelete, title, activeTab, setActiveTab, type, handleTVSelect, selectedTvItem, templateObj }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setActiveTab(type)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const filteredItems = items
        ?.filter(item =>
            (templateObj?.templateName === "Signup Screen" && item?.templateName === "Signup Screen") ||
            (templateObj?.templateName === "Login Screen" && item?.templateName === "Login Screen")
        )
        .filter(item => item?.platform?.includes(activeTab));
    const liveItems = filteredItems?.filter(item => item.status === "Live") || [];
    const draftItems = filteredItems?.filter(item => item.status === "Draft") || [];

    console.log(items, activeTab, filteredItems, "xyz")
    return (
        <div>
            <Button variant="text" sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: activeTab === type ? 'bold' : 'normal',
                color: activeTab === type ? '#3467C6' : '#333',
                bgcolor: activeTab === type ? '#CDDEFF' : '',
                fontSize: "12px"
            }} onClick={handleClick} endIcon={<ArrowDropDownIcon />}>
                <TvIcon sx={{ mr: 1 }} />
                {title}
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {liveItems?.length > 0 && (
                    <>
                        <Divider textAlign="center">Live</Divider>
                        {liveItems?.map(item => (
                            <MenuItem key={item._id}
                                onClick={() => {
                                    handleTVSelect(item);
                                    setSelectedItem(item.draftName);
                                    setAnchorEl(null);
                                }}
                                sx={{
                                    backgroundColor: selectedItem === item.draftName ? '#CDDEFF' : 'transparent',
                                    fontWeight: selectedItem === item.draftName ? 'bold' : 'normal',
                                }}
                            >
                                {item.draftName}
                                <IconButton
                                    edge="end"
                                    color="secondary"
                                    onClick={() => onDelete(item.draftName)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </MenuItem>
                        ))}
                    </>
                )}

                {/* Render Draft Items */}
                {draftItems?.length > 0 && (
                    <>
                        <Divider textAlign="center">Draft</Divider>
                        {draftItems?.map(item => (
                            <MenuItem key={item._id}
                                onClick={() => {
                                    handleTVSelect(item);
                                    setSelectedItem(item.draftName);
                                    setAnchorEl(null);
                                }}
                                sx={{
                                    backgroundColor: selectedItem === item.draftName ? '#CDDEFF' : 'transparent',
                                    fontWeight: selectedItem === item.draftName ? 'bold' : 'normal',
                                }}
                            >
                                {item.draftName}
                                <IconButton
                                    edge="end"
                                    color="secondary"
                                    onClick={() => onDelete(item.draftName)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </MenuItem>
                        ))}
                    </>
                )}
            </Menu>
        </div>

    );
};

export default CustomDropdown;
