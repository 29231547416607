import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { connect } from "react-redux";

import TvIcon from "@mui/icons-material/Tv";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import StyledSelect from "./Components/SelectComponent";
import Dropdown from "./DropDown";
import CustomDropdown from "./DeviceDropDown";
import PreviewScreen from "./Preview";
import { ReactComponent as EditSvg } from "../../assets/img/SignInTemplateAssets/edit.svg";
import LiveIcon from "../../assets/img/SignInTemplateAssets/Live.svg";
import NotLiveIcon from "../../assets/img/SignInTemplateAssets/Not Live.svg";
import MediaDropdown from "./Components/MediaDropDown";
import SocialMediaDropdown from "./Components/SocialMediaDropDown";
import ImageColorDropdown from "./Components/ImageColorDropDown";
import { getAllTemplateData, getAllTemplateAction, createTemplateAction, updateTemplateAction } from "./actions/templateActions";

const LoginPage = (props) => {
  const [activeTab, setActiveTab] = useState("TV");
  const [selectedType, setSelectedType] = useState("");
  const [isLogin, setIsLogin] = useState(false)
  const [formSettings, setFormSettings] = useState({
    position: "Right Side",
    fontSize: "20px",
    fontColor: "#000000",
    fieldColor: "#ffffff",
    buttonColor: "#0056d2",
    media: "",
    socialMedia: "",
    background: "",
  });
  const [templateObj, setTemplateObj] = useState({
    "templateName": "Login Screen",
    "platform": ["Tv"],
    "media": {
      "file": "",
      "type": "",
      "slideshow": [],
      "fileURL": "",
      "position": "Left",
    },
    "background": {
      "imageUrl": "",
      "backgroundColor": ""
    },
    "socialMedia": {
      "checkboxes": {
        "google": false,
        "facebook": false,
        "whatsapp": false
      },
      "settings": {
        "fontFamily": "Arial",
        "fontColor": "#000000",
        "buttonColor": "#3f51b5",
        "type": "Logo Name",
        "position": "Part of Form",
        "order": "Horizontal"
      }
    },
    "form": {
      "type": "Email",
      "position": "Right",
      "fontSize": 16,
      "fontColor": "#9b3131",
      "fieldColor": "#ce5f5f",
      "buttonColor": "#3636dd",
      "currentFont": "Arial"
    },
    "status": "Draft",
    "draftName": "Initial Draft"
  }
  );
  const [isEditing, setIsEditing] = useState(false);
  const [tvLogin, setTvLogin] = useState("");
  const [tvItemSelected, setTvItemSelected] = useState({})
  const [dropdownItems, setDropdownItems] = useState([
    { label: "Live - Item 1", type: "live" },
  ]);

  useEffect(() => {
    const payload = {
      p_id: props?.projectDetails?.p_id,
      t_id: props?.authToken?.t_id
    }
    console.log(payload,"oottt");
    
    props.getAllTemplateAction(payload)
  }, [props?.updateTemplate, props?.createTemplate])

  useEffect(() => {
    if (props.templateData) {
      setDropdownItems(props?.templateData?.data);
    }
  }, [props.templateData]);

  useEffect(() => {
    if (props.templateData) {
      setTemplateObj((prevState) => ({
        ...prevState,
        platform: [activeTab],
      }));
    }
  }, [activeTab]);


  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };




  const handleDelete = (label) => {
    setDropdownItems((prevItems) =>
      prevItems.filter((item) => item.draftName !== label)
    );
  };
  const handleSave = () => {
    setDropdownItems((prevItems) =>
      prevItems.map((item) =>
        item.draftName === tvItemSelected?.draftName
          ? { ...item, draftName: templateObj?.draftName }
          : item
      )
    );
    setTvItemSelected({ ...tvItemSelected, templateName: templateObj?.draftName });
    setIsEditing(false);
  };

  const handleSaveAsDraft = () => {
    const updatedTemplateObj = { ...templateObj, status: "Draft" };
    setTemplateObj(updatedTemplateObj);
    const payload = {
      templateObj: updatedTemplateObj,
      ids: { p_id: props?.projectDetails?.p_id, t_id: props?.authToken?.t_id },
    };

    console.log(payload,"Saving as Draft");
    props.createTemplateAction(payload);
  };

  const handlePublish = () => {
    const updatedTemplateObj = { ...templateObj, status: "Live" };
    setTemplateObj(updatedTemplateObj);
    const payload = {
      templateObj: updatedTemplateObj,
      ids: { p_id: props?.projectDetails?.p_id, t_id: props?.authToken?.t_id },
    };
    props.updateTemplateAction(payload);
  }

  const handleTVSelect = (data) => {
    console.log(data, "handleTVSelect")
    setTvItemSelected(data);
    setTvLogin(data?.draftName);
    setTemplateObj(data)
  }
  const handleChangeTemplateName = (e) => {
    setTemplateObj((prevState) => ({
      ...prevState,
      draftName: e.target.value,
    }));
  }
  const handleChangeTemplate = (template) => {
    setTemplateObj((prevState) => ({
      ...prevState,
      templateName: template === 'login' ? "Login Screen" : "Signup Screen",
    }));
  };

  console.log(props.templateData, "templateData")
  console.log(templateObj, "act");

  return (
    <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "12px",
            bgcolor: "#E9EAFF",
            padding: "5px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <Button
            variant=""
            sx={{
              bgcolor: templateObj?.templateName === "Login Screen" ? "#CDDEFF" : "#DDDDDD",
              mx: 1,
              borderRadius: "10px",
              fontSize: "12px",
            }}
            onClick={() => handleChangeTemplate('login')}
          >
            Login
          </Button>
          <Button
            variant=""
            sx={{
              bgcolor: templateObj?.templateName === "Signup Screen" ? "#CDDEFF" : "#DDDDDD",
              borderRadius: "10px",
              fontSize: "12px",
            }}
            onClick={() => handleChangeTemplate('signup')}
          >
            Signup
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          height: "90vh",
          display: "flex",
          flexDirection: "column",
          bgcolor: "#E9EAFF",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            p: 2,
            bgcolor: "#f0f0f0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {/* Tabs */}
          <Box sx={{ display: "flex" }}>
            <Box>
              <CustomDropdown
                items={dropdownItems}
                onDelete={handleDelete}
                title={"TV"}
                type={"TV"}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                handleTVSelect={handleTVSelect}
                selectedTvItem={tvItemSelected}
                templateObj={templateObj}
              />
            </Box>
            <Box>
              <CustomDropdown
                items={dropdownItems}
                onDelete={handleDelete}
                title={"Desktop, Web, Tablet"}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                type={"Desktop"}
                handleTVSelect={handleTVSelect}
                selectedTvItem={tvItemSelected}
                templateObj={templateObj}
              />
            </Box>
            <Box>
              <CustomDropdown
                items={dropdownItems}
                onDelete={handleDelete}
                title={"Mobile"}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                type={"Mobile"}
                handleTVSelect={handleTVSelect}
                selectedTvItem={tvItemSelected}
                templateObj={templateObj}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            {/* Editable TV Login Field */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {isEditing ? (
                <>
                  <TextField
                    value={templateObj?.draftName || "Template"}
                    onChange={(e) => handleChangeTemplateName(e)}
                    variant="outlined"
                    size="small"
                    sx={{
                      fontSize: "12px",
                      bgcolor: "#CDDEFF",
                      mr: 1,
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px",
                      },
                    }}
                  />
                  <Button
                    onClick={handleSave}
                    variant="contained"
                    size="small"
                    sx={{ fontSize: "12px" }}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => setIsEditing(true)} // Enable editing mode
                    sx={{ minWidth: "24px", padding: 0 }}
                  >
                    <EditSvg style={{ width: "16px", height: "16px" }} />
                  </Button>
                  <Button
                    onClick={() => setIsEditing(true)} // Enable editing mode
                    variant="text"
                    sx={{
                      fontWeight: "normal",
                      color: "#333",
                      fontSize: "12px",
                    }}
                  >
                    {templateObj?.draftName}
                  </Button>
                </>
              )}
            </Box>

            {/* Status Button with Dynamic Icon */}
            <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", color: "#333" }}
              >
                Status
              </Typography>
              {templateObj?.status === "Draft" ? <img
                src={NotLiveIcon}
                alt="Status Icon"
                style={{ width: "25px", height: "25px", marginLeft: "5px" }}
              /> : <img
                src={LiveIcon}
                alt="Status Icon"
                style={{ width: "25px", height: "25px", marginLeft: "5px" }}
              />}
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              onClick={() => handleSaveAsDraft()}
              variant="text"
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                color: "#333",
                bgcolor: "#979797",
                fontSize: "12px",
                borderRadius: "10px",
              }}
            >
              Save as Draft
            </Button>

            <Button
              onClick={() => handlePublish()}
              variant="text"
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                color: "#fff",
                bgcolor: "#3F96EF",
                fontSize: "12px",
                borderRadius: "10px",
              }}
            >
              Publish Changes
            </Button>
          </Box>
        </Box>

        {/* Main Content Section */}
        <Grid
          container
          sx={{ flexGrow: 1, bgcolor: "#E9EAFF", height: "100vh" }}
        >
          <PreviewScreen
            background="path/to/background-image.jpg"
            logoSrc="path/to/logo-image.png"
            logoPosition="left"
            formPosition="center"
            componentType="logo"
            fontSize="1.2rem"
            fontColor="#333"
            buttonColor="#205AC8"
            buttonTextColor="#ffffff"
            templateObj={templateObj}
          />
        </Grid>

        {/* Footer (Settings) */}
        <Box sx={{ p: 2, bgcolor: "#f0f0f0" }}>
          <Grid container spacing={1}>
            <Grid item container xs={12} md={10} spacing={1}>
              <Grid item xs={6} md={2}>
                <Dropdown setTemplateObj={setTemplateObj} templateObj={templateObj} />
              </Grid>

              <Grid item xs={6} md={2}>
                <MediaDropdown setTemplateObj={setTemplateObj} templateObj={templateObj} />
              </Grid>
              <Grid item xs={6} md={2}>
                <SocialMediaDropdown setTemplateObj={setTemplateObj} templateObj={templateObj} />
              </Grid>
            </Grid>
            <Grid item xs={12} md={2}>
              <ImageColorDropdown setTemplateObj={setTemplateObj} templateObj={templateObj} />
            </Grid>
          </Grid>
        </Box>

      </Box>
    </Box>
  );
};

const mapStateToProps = (State) => {
  return {
    templateData: State.TemplateReducer.templateData,
    createTemplate: State.TemplateReducer.createTemplate,
    updateTemplate: State.TemplateReducer.updateTemplate,
    projectDetails: State.ProjectReducer.projectDetails,
    authToken: State.loginReducer.authTokens,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllTemplateAction: (data) => {
      dispatch(getAllTemplateAction(data));
    },
    createTemplateAction: (data) => {
      dispatch(createTemplateAction(data));
    },
    updateTemplateAction: (data) => {
      dispatch(updateTemplateAction(data));
    }


  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
