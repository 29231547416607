import { all } from "redux-saga/effects";
import watchContentExplorerSaga from "screens/ContactExplorer/sagas/contentExploereSaga";
import watchDictSaga from "screens/MessageDict/saga";
import watchVisualizationScreenSaga from "screens/PlatformManager/saga";
import { watchCreateNewProject } from "screens/Projects/sagas/createProjectSaga";
import { watchGetAllProjects } from "screens/Projects/sagas/getProjectsSaga";
import { watchLoginAction } from "screens/signin/sagas/loginSaga";
import { watchUsersSaga } from "screens/Users/sagas";
import blackoutScheduleWatcher from "../screens/BlackoutSchedule/saga";
import widgetMangerWatcher from "../screens/WidgetManager/sagas";
import pushnotificationMangerWatcher from "screens/PushNotification/sagas";
import { watchPackageSaga } from "screens/packages/saga";
import { watchCreateNewTemplate } from "screens/SininTemplate/sagas/createProjectSaga";

export default function* rootSaga() {
  yield all([
    watchLoginAction(),
    watchGetAllProjects(),
    watchCreateNewProject(),
    widgetMangerWatcher(),
    pushnotificationMangerWatcher(),
    watchVisualizationScreenSaga(),
    watchContentExplorerSaga(),
    watchUsersSaga(),
    blackoutScheduleWatcher(),
    watchDictSaga(),
    watchPackageSaga(),
    watchCreateNewTemplate()
  ]);
}
