import React, { useState, useRef } from 'react';
import {
    Menu,
    MenuItem,
    Button,
    Tabs,
    Tab,
    Divider,
    Slider,
    TextField,
    Box,
} from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import {
    ArrowDropDown as ArrowDropDownIcon,
} from '@mui/icons-material';
import BackgroundIcon from "../../../assets/img/SignInTemplateAssets/backgroundIcon.svg"

const ImageColorDropdown = ({setTemplateObj,templateObj}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [image, setImage] = useState(null);
    const [imageFileName, setImageFileName] = useState('');
    const [color, setColor] = useState('#000000');

    const fileInputRef = useRef(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
                setImage(fileReader.result);
                setImageFileName(file.name);
                setTemplateObj({...templateObj,background:{imageUrl:fileReader.result}})
            };
            fileReader.readAsDataURL(file);
        }
    };

    const handleReplaceImage = () => {
        // Trigger file input click
        // if (fileInputRef.current) {
        //   fileInputRef.current.click(); // Open file selection dialog
        // }
        document.getElementById('image-replace').click();
    };
console.log(image,"image");

    return (
        <div>
            <Button
                variant="outlined"
                onClick={handleMenuOpen}
                startIcon={<img src={BackgroundIcon} alt="form Icon" width={20} height={20} />}
                endIcon={<ArrowDropDownIcon />}
            >
                Background
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="Tabs"
                    variant="fullWidth"
                    TabIndicatorProps={{ style: { display: 'none' } }}
                >
                    <Tab label="Image" sx={{ flex: 1 }} />
                    <Tab label="Color" sx={{ flex: 1 }} />
                </Tabs>
                <Divider/>

                {selectedTab === 0 && (
                    <Box sx={{ padding: '10px', width: '300px' }}>
                        {/* Image Tab */}
                        {image ? (
                            <Box>
                                <img
                                    src={image}
                                    alt="Uploaded Preview"
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        maxHeight: '100px',
                                        objectFit: 'contain',
                                    }}
                                />
                                <div>{imageFileName}</div>
                                <Divider />
                                <Button
                                    variant="outlined"
                                    onClick={handleReplaceImage}
                                    startIcon={<CloudUploadIcon />}
                                    style={{ marginTop: '10px' }}
                                >
                                    Replace Image
                                </Button>
                            </Box>
                        ) : (
                            <Box>
                                <Button
                                    variant="outlined"
                                    component="label"
                                    startIcon={<CloudUploadIcon />}
                                    style={{ width: '100%', marginBottom: '10px' }}
                                >
                                    Upload Image
                                    <input
                                        type="file"
                                        ref={fileInputRef} // Attach ref to file input
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                        hidden
                                    />
                                </Button>
                                <Divider />
                                <div>No image uploaded yet</div>
                            </Box>
                        )}
                    </Box>
                )}

                {selectedTab === 1 && (
                    <Box sx={{ padding: '10px', width: '300px' }}>
                        {/* Color Tab */}
                        <Box
                            sx={{
                                marginBottom: '10px',
                                height: '80px',
                                width: '100%',
                                backgroundColor: color,
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                            }}
                        />
                        <TextField
                            label="Select Color"
                            type="color"
                            value={color}
                            onChange={(e) =>{ setColor(e.target.value);
                                setTemplateObj({...templateObj,background:{...templateObj.background,backgroundColor:e.target.value}})
                            }}
                            fullWidth
                        />
                    </Box>

                )}
            </Menu>

            {/* The hidden file input is only rendered once, ensuring the file dialog is opened when clicked */}
            <input
                id='image-replace'
                type="file"
                ref={fileInputRef}
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleImageUpload}
            />
        </div>
    );
};

export default ImageColorDropdown;
