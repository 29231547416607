const contentExplorerStyle = {
    heading:{
        // width: '201px',
        // height: '33px',
        'font-family': 'Open Sans',
        'font-style': 'normal',
        'font-weight': '700',
        'font-size': '24px',
        'line-height': '33px',
        color: '#141D46',
    },
    tab:{
        width: '208px',
        maxWidth:'100%',
        height: '56px',
        //background: '#FFFFFF',
        //'border-radius': '12px 12px 0px 0px'
        color:"#141D45",
    },
    unSelectedTab:{
        width: '204px',
        maxWidth:'100%',
        height: '56px',
        color:"#141D45",
    },
    textfield:{
        boxSizing: 'border-box',
        background: '#FCFCFC',
        border: '1px  #C7C7C7',
        borderRadius: '8px',
        "& input::placeholder":{
            fontSize: "16px",
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '140%',
            color: '#777777',
        }
    },
    addButton:{
        width:'180px',
        height:'40px',
        background: 'linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)',
        boxShadow: '0px 2px 20px rgba(0, 60, 118, 0.4)',
        'border-radius': '33px'
    },
    refreshButton:{
        marginTop:'8px',
        marginLeft:'8px',
       // width:'180px',
        height:'40px',
        //cursor:'pointer'
       // boxShadow: '0px 2px 20px rgba(0, 60, 118, 0.4)',
       
    },
    ButtonText:{
        // width: '201px',
        // height: '22px',
        // left: 'calc(50% - 311px/2 - 0.5px)',
        // top: 'calc(50% - 22px/2)',
        'font-family': 'Open Sans',
        'font-style': 'normal', 
        'font-weight': 700,
        'font-size': '16px',
        'line-height': '140%',
        'text-align': 'center',
        'color': '#FFFFFF'
    },
    text:{
        'font-family': 'Open Sans',
        'font-style': 'normal', 
        'font-weight': 700,
        'font-size': '16px',
        'line-height': '140%',
        'color': '#FFFFFF'
    },
    cardTitle:{
        width: '250px',
        height: '27px',
        left: '132px',
        top: '363px',
        'font-family': 'Open Sans',
        'font-style': 'normal',
        'font-weight': '700',
        'font-size': '20px',
        'line-height': '27px',
        'color': '#141D46',
    },
    card:{
        width: '100%',
        height: 'auto',
        left: '108px',
        top: '665px',
        background: 'rgba(255, 255, 255, 0.6)',
        'box-shadow': '0px 8px 20px rgba(63, 39, 102, 0.12)',
        'backdrop-filter': 'blur(20px)',
        'border-radius': '24px',
    },
    title:{
        width: '180px',
        height: '20px',
        'font-family': 'Open Sans',
        'font-style': 'normal',
        'font-weight': '400',
        'font-size': '14px',
        'line-height': '20px',
        color: '#777777',
    },
    ImageCard:{
        width: '388px', 
        height: '313px',
        'background': '#FFFFFF',
        'border-radius': '8px',
    },
    ImageRatio:{
        width: '200px',
        height: '22px',
        'font-family': 'Open Sans',
        'font-style': 'normal',
        'font-weight': '600',
        'font-size': '16px',
        'line-height': '22px',
        color: '#141D46',
    },
    image:{
        position: 'relative',
        maxWidth: '100%',
        maxHeight:'100%',
        width:'310px',
        height:'174px',
        'padding-top': '1px',
    },
    image_2:{
        position: 'relative',
        maxWidth: '100%',
        maxHeight:'100%',
        width:'104px',
        height:'174px',
        'padding-top': '1px',
    },
    image_3:{
        position: 'relative',
        maxWidth: '100%',
        maxHeight:'100%',
        width:'174px',
        height:'174px',
        'padding-top': '1px',
    },
    visualization:{
        width: '100%',
        height: 'auto',
        left: '108px',
        top: '665px',
        background:'#10183c',
        'box-shadow': '0px 8px 20px rgba(63, 39, 102, 0.12)',
        'backdrop-filter': 'blur(20px)',
        'border-radius': '24px',
    },
    treeText:{
        width: '11px',
        height: '22px',
        left: 'calc(50% - 311px/2 - 0.5px)',
        top: 'calc(50% - 22px/2)',
        'font-family': 'Open Sans',
        'font-style': 'normal', 
        'font-weight': 700,
        'font-size': '40px',
        'line-height': '140%',
        'text-align': 'center',
        'color': '#FFFFFF'

    },
    fullScreen:{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "100%",
        height:'100%',
        p: 4,
        background:'#10183c',
        'box-shadow': '0px 8px 20px rgba(63, 39, 102, 0.12)',
        'backdrop-filter': 'blur(20px)',
        'border-radius': '24px',
    },
    FullScreenText:{
        width: '100px',
        height: '22px',
        left: 'calc(50% - 311px/2 - 0.5px)',
        top: 'calc(50% - 22px/2)',
        'font-family': 'Open Sans',
        'font-style': 'normal', 
        'font-weight': 700,
        'font-size': '16px',
        'line-height': '140%',
        'text-align': 'center',
        'color': '#FFFFFF'
    },
    closeButton:{
        width:'40px',
        height:'35px',
        background: 'White',
        boxShadow: '0px 2px 20px rgba(0, 60, 118, 0.4)',
        'border-radius': '33px',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#3c52b2',
        }
    },
    playlistText:{
        width: "178px",
        height: "33px",
        left: "317px",
        top: "318px",
        "font-family": 'Open Sans',
        "font-style": "normal",
        "font-weight": "700",
        "font-size": "24px",
        "line-height": "33px",
    },
    subText:{
        width: "auto",
        height: "20px",
        left: "317px",
        top: "391px",
        fontFamily: 'Open Sans',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#777777",
        paddingTop:'2%',
        paddingLeft:'3%'
    },
    subText1:{
        width: "auto",
        height: "20px",
        left: "317px",
        top: "391px",
        fontFamily: 'Open Sans',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        paddingLeft:'3%'
    },
    playlistBox:{
        display:'flex',
        flexDirection:'row', 
        overflow:'auto',
        paddingRight:'1%',
        "&::-webkit-scrollbar": {
            display: "none",
        },
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
    }
}

export default contentExplorerStyle;