import React, { useState } from 'react';
import {
    Button,
    Menu,
    MenuItem,
    Divider,
    ListItemText,
    Input,
    Alert,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ChevronRightIcon from '@mui/icons-material/ChevronRight'; 
import ClearIcon from '@mui/icons-material/Clear';
import ImageIcon from '@mui/icons-material/Image'; 
import VideoCameraIcon from '@mui/icons-material/Camera';
import MediaIcon from "../../../assets/img/SignInTemplateAssets/mediaSelect.svg"

import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'; 

const MediaDropdown = ({setTemplateObj,templateObj}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentMedia, setCurrentMedia] = useState({
        file: '',
        type: '', 
        slideshow: [],
        position:'',
        fileURL:'',
    });
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null); 
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedSlideshow, setSelectedSlideshow] = useState([]); 
    const [isPositionSubMenuOpen, setIsPositionSubMenuOpen] = useState(false);
    const [positionAnchorEl, setPositionAnchorEl] = useState(null);

    const updateTemplateMedia = (updatedMedia) => {
        setTemplateObj((prevTemplateObj) => ({
            ...prevTemplateObj,
            media: updatedMedia,
        }));
    };

    // Open the main menu
    const handleMainMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Close the main menu
    const handleMainMenuClose = () => {
        setAnchorEl(null);
    };

    // Handle file change for image
    const handleFileChange = (event) => {
        const file = event.target.files[0]; 
        if (file && file.type.startsWith("image/")) {
            const fileURL = URL.createObjectURL(file); 
            console.log(fileURL,"fileURL");
            setSelectedFile({ fileName: file.name, fileURL });
            const updatedMedia = { ...currentMedia, file: file.name, type: 'image',fileURL:fileURL };
            setCurrentMedia({ ...currentMedia, file: file.name, type: 'image' ,fileURL:fileURL});
            setSelectedVideo(null); 
            setSelectedSlideshow([]); 
            updateTemplateMedia(updatedMedia);
            event.target.value = null; 
        } else {
            alert("Please select an image file.");
        }
    };

    // Handle file change for video
    const handleVideoChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith("video/")) {
            const fileURL = URL.createObjectURL(file); 
            setSelectedVideo({ fileName: file.name, fileURL });
            const updatedMedia = { ...currentMedia, file: file.name, type: 'video' };

            setCurrentMedia({ ...currentMedia, file: file.name, type: 'video' });
            setSelectedFile(null);
            setSelectedSlideshow([]); 
            updateTemplateMedia(updatedMedia);
            event.target.value = null; 
        } else {
            alert("Please select a video file.");
        }
    };
    const handleSlideshowChange = (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            const fileURLs = files.map((file) => URL.createObjectURL(file));
            
            // Combine existing and new files for the slideshow
            const updatedSlideshow = [
                ...currentMedia.slideshow,
                ...files.map((file) => file.name)
            ];
    
            const updatedSlideshowUrls = [
                ...selectedSlideshow,
                ...files.map((file, index) => ({
                    fileName: file.name,
                    fileURL: fileURLs[index]
                }))
            ];
    
            const updatedMedia = {
                ...currentMedia,
                type: 'slideshow',
                slideshow: updatedSlideshow
            };
    
            setSelectedSlideshow(updatedSlideshowUrls);
            setCurrentMedia(updatedMedia);
            setSelectedFile(null); // Clear selected file
            setSelectedVideo(null); // Clear selected video
            updateTemplateMedia(updatedMedia);
    
            event.target.value = null; // Reset input
        }
    };
    

    // Handle replacing the current photo
    const handleReplacePhoto = () => {
        document.getElementById('file-input').click();
    };

    // Handle replacing the current video
    const handleReplaceVideo = () => {
        document.getElementById('video-input').click();
    };

    // Handle replacing the current slideshow
    const handleReplaceSlideshow = () => {
        document.getElementById('slideshow-input').click();
    };

    // Handle clearing the media
    const handleClearMedia = () => {
        const clearedMedia = { file: '', type: '', slideshow: [] };

        setCurrentMedia(clearedMedia);
        setSelectedFile(null);
        setSelectedVideo(null);
        setSelectedSlideshow([]);
        updateTemplateMedia(clearedMedia);
    };

    // Handle changing the position menu
    const handlePositionMenuOpen = (event) => {
        setPositionAnchorEl(event.currentTarget);
        setIsPositionSubMenuOpen(true);
    };

    const handlePositionMenuClose = () => {
        setIsPositionSubMenuOpen(false);
    };

    // Handle position change
    const handlePositionChange = (position) => {
        setCurrentMedia((prevMedia) => {
            // Ensure `fileURL` is retained by updating only `position`
            const updatedMedia = { ...prevMedia, position: position };
            updateTemplateMedia(updatedMedia); // Assuming this needs the updated media
            return updatedMedia;
        });
        handlePositionMenuClose();
    };

    // Check if any media has been added (photo, video, or slideshow)
    const isMediaAdded = currentMedia.type !== '';
    

    return (
        <div>
            <Button
                variant="outlined"
                color="primary"
                onClick={handleMainMenuOpen}
                startIcon={<img src={MediaIcon} alt="form Icon" width={20} height={20} />} 

                endIcon={<ArrowDropDownIcon />}
            >
                Media
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMainMenuClose}
                PaperProps={{ style: { width: '300px' } }}
            >
                {/* Add Media Options with Plus Icon */}
                <MenuItem
                    onClick={() => document.getElementById('file-input').click()}
                >
                    <AddCircleIcon style={{ marginRight: '10px' }} />
                    Add Photo
                </MenuItem>
                <MenuItem
                    onClick={() => document.getElementById('video-input').click()}
                >
                    <AddCircleIcon style={{ marginRight: '10px' }} />
                    Add Video
                </MenuItem>
                <MenuItem
                    onClick={() => document.getElementById('slideshow-input').click()}
                >
                    <AddCircleIcon style={{ marginRight: '10px' }} />
                    Add Slideshow
                </MenuItem>

                <Divider />

                {/* Current Media Section */}
                <MenuItem disabled>
                    <ListItemText
                        primary="Current Media"
                        style={{ textAlign: 'center', fontWeight: 'bold' }}
                    />
                </MenuItem>

                {/* Display File Section */}
                {currentMedia.type === 'image' && selectedFile ? (
                    <div>
                        <MenuItem onClick={() => setIsSubMenuOpen((prev) => !prev)}>
                            <ImageIcon style={{ marginRight: '10px' }} /> {/* Image icon */}
                            <ListItemText primary={`File: ${currentMedia.file}`} />
                            <ArrowDropDownIcon />
                        </MenuItem>

                        {/* Submenu for managing the uploaded file */}
                        {isSubMenuOpen && (
                            <Menu
                                open={isSubMenuOpen}
                                onClose={() => setIsSubMenuOpen(false)}
                                anchorEl={anchorEl}
                            >
                                <MenuItem disabled>
                                    <ImageIcon style={{ marginRight: '10px' }} /> {/* Image icon */}
                                    <strong>{currentMedia.file}</strong>
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleClearMedia} style={{ color: 'red' }}>
                                    <ClearIcon style={{ marginRight: '10px' }} /> Delete Photo
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleReplacePhoto}>
                                    <PhotoLibraryIcon style={{ marginRight: '10px' }} /> Replace Photo
                                </MenuItem>
                            </Menu>
                        )}
                    </div>
                ) : currentMedia.type === 'video' && selectedVideo ? (
                    <div>
                        <MenuItem onClick={() => setIsSubMenuOpen((prev) => !prev)}>
                            <VideoCameraIcon style={{ marginRight: '10px' }} /> {/* Video icon */}
                            <ListItemText primary={`Video: ${currentMedia.file}`} />
                            <ArrowDropDownIcon />
                        </MenuItem>

                        {/* Submenu for managing the video */}
                        {isSubMenuOpen && (
                            <Menu
                                open={isSubMenuOpen}
                                onClose={() => setIsSubMenuOpen(false)}
                                anchorEl={anchorEl}
                            >
                                <MenuItem disabled>
                                    <strong>{currentMedia.file}</strong>
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleClearMedia} style={{ color: 'red' }}>
                                    <ClearIcon style={{ marginRight: '10px' }} /> Delete Video
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleReplaceVideo}>
                                    <PhotoLibraryIcon style={{ marginRight: '10px' }} /> Replace Video
                                </MenuItem>
                            </Menu>
                        )}
                    </div>
                ) : currentMedia.type === 'slideshow' && selectedSlideshow.length > 0 ? (
                    <div>
                        <MenuItem onClick={() => setIsSubMenuOpen((prev) => !prev)}>
                            <PhotoLibraryIcon style={{ marginRight: '10px' }} /> {/* Slideshow icon */}
                            <ListItemText primary={`Slideshow`} />
                            <ArrowDropDownIcon />
                        </MenuItem>

                        {/* Submenu for managing the slideshow */}
                        {isSubMenuOpen && (
                            <Menu
                                open={isSubMenuOpen}
                                onClose={() => setIsSubMenuOpen(false)}
                                anchorEl={anchorEl}
                            >
                                <MenuItem disabled>
                                    <strong>{selectedSlideshow.length} items</strong>
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleClearMedia} style={{ color: 'red' }}>
                                    <ClearIcon style={{ marginRight: '10px' }} /> Delete Slideshow
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleReplaceSlideshow}>
                                    <PhotoLibraryIcon style={{ marginRight: '10px' }} /> Replace Slideshow
                                </MenuItem>
                            </Menu>
                        )}
                    </div>
                ) : null}

                <Divider />

                {/* Position Selection */}
                <MenuItem
                    onClick={handlePositionMenuOpen}
                    disabled={!isMediaAdded} // Disable if no media is added
                >
                    Change Position <ChevronRightIcon style={{ marginLeft: 'auto' }} />
                </MenuItem>

                {isPositionSubMenuOpen && (
                    <Menu
                        anchorEl={positionAnchorEl}
                        open={isPositionSubMenuOpen}
                        onClose={handlePositionMenuClose}
                    >
                        <MenuItem onClick={() => handlePositionChange('Left')}>Left</MenuItem>
                        <MenuItem onClick={() => handlePositionChange('Right')}>Right</MenuItem>
                        <MenuItem onClick={() => handlePositionChange('Center')}>Center</MenuItem>
                    </Menu>
                )}
            </Menu>

            {/* Hidden Inputs for File Selection */}
            <Input
                id="file-input"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
            <Input
                id="video-input"
                type="file"
                accept="video/*"
                onChange={handleVideoChange}
                style={{ display: 'none' }}
            />
            <Input
                id="slideshow-input"
                type="file"
                accept="image/*"
                multiple
                onChange={handleSlideshowChange}
                style={{ display: 'none' }}
            />
        </div>
    );
};

export default MediaDropdown;
