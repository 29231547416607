import React, { useState ,useEffect} from 'react';
import {
    Menu, MenuItem, Checkbox, Divider, FormControlLabel,
    Button, Switch, IconButton
} from '@mui/material';
import {
    ArrowDropDown as ArrowDropDownIcon,
    ArrowForwardIos as ArrowForwardIosIcon,
    Clear as ClearIcon
} from '@mui/icons-material';
import SocialIcon from "../../../assets/img/SignInTemplateAssets/socialIcon.svg"

const SocialMediaDropdown = ({setTemplateObj,templateObj}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
    const [isSocialLoginEnabled, setIsSocialLoginEnabled] = useState(true);

    const [checkboxes, setCheckboxes] = useState({
        google: false,
        facebook: false,
        whatsapp: false
    });

    const [settings, setSettings] = useState({
        fontFamily: 'Arial',
        fontColor: '#000000',
        buttonColor: '#3f51b5',
        type: 'Logo',
        position: 'Below Form',
        order: 'Vertical'
    });

    const [subMenus, setSubMenus] = useState({
        type: false,
        position: false,
        order: false,
        font: false
    });

    useEffect(() => {
        setTemplateObj({
            ...templateObj,socialMedia:{checkboxes,settings}
        });
    }, [checkboxes, settings]);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
        setSubMenus({ type: false, position: false, order: false, font: false });
    };

    const handleToggle = () => {
        setIsSocialLoginEnabled(!isSocialLoginEnabled);
    };

    const handleCheckboxChange = (name) => (event) => {
        setCheckboxes({ ...checkboxes, [name]: event.target.checked });
       
    };

    const handleSettingChange = (name, value) => {
        const key = name === 'font' ? 'fontFamily' : name; // Map 'font' to 'fontFamily'

        console.log(`Updating ${key} to ${value}`); // Debugging line
        setSettings((prevSettings) => {
            const newSettings = { ...prevSettings, [key]: value };
            console.log('New settings:', newSettings);
           
            // Debugging line
            return newSettings;
        });
        setSubMenus({ ...subMenus, [name]: false });
        setSubMenuAnchorEl(null); // Close the submenu after selection
    };

    const handleSubMenuToggle = (event, name) => {
        setSubMenuAnchorEl(event.currentTarget);
        setSubMenus({ ...subMenus, [name]: !subMenus[name] });
    };

    const renderSubMenu = (name, options) => (
        subMenus[name] && (
            <Menu
                anchorEl={subMenuAnchorEl}
                open={Boolean(subMenuAnchorEl) && subMenus[name]}
                onClose={() => setSubMenus({ ...subMenus, [name]: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option}
                        onClick={() => handleSettingChange(name, option)}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        )
    );

    return (
        <div>
            <Button
                variant="outlined"
                color="primary"
                onClick={handleMenuOpen}
                startIcon={<img src={SocialIcon} alt="form Icon" width={20} height={20} />}
                endIcon={<ArrowDropDownIcon />}
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '5px 16px',
                    whiteSpace: 'nowrap',
                    width: '100%'
                }}
            >
                Social Media
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem>
                    <FormControlLabel
                        control={<Switch checked={isSocialLoginEnabled} onChange={handleToggle} />}
                        label="Social Media Login"
                        labelPlacement="start"
                    />
                </MenuItem>
                <Divider />

                {['type', 'position', 'order'].map((name) => (
                    <React.Fragment key={name}>
                        <MenuItem
                            onClick={(event) => handleSubMenuToggle(event, name)}
                            disabled={!isSocialLoginEnabled}
                        >
                            {`${name.charAt(0).toUpperCase() + name.slice(1)}: ${settings[name]}`}
                            <ArrowForwardIosIcon style={{ marginLeft: 'auto' }} />
                        </MenuItem>
                        {renderSubMenu(name,
                            name === 'type' ? ['Logo', 'Logo Name'] :
                                name === 'position' ? ['Below Form', 'Part of Form'] :
                                    ['Vertical', 'Horizontal']
                        )}
                    </React.Fragment>
                ))}

                <Divider />

                {/* Font selection menu item */}
                <MenuItem
                    onClick={(event) => handleSubMenuToggle(event, 'font')}
                    disabled={!isSocialLoginEnabled}
                >
                    {console.log(settings.fontFamily, "settings.fontFamily")
                    }
                    {`Font: ${settings.fontFamily}`} {/* Display the selected font family dynamically */}
                    <ArrowForwardIosIcon style={{ marginLeft: 'auto' }} />
                </MenuItem>
                {renderSubMenu('font', ['Arial', 'Courier New', 'Times New Roman'])}

                {['Font', 'Button'].map((colorType) => (
                    <MenuItem key={colorType} disabled={!isSocialLoginEnabled}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>{`${colorType} Color:`}</span>
                            <input
                                type="color"
                                value={settings[`${colorType.toLowerCase()}Color`]}
                                onChange={(e) => handleSettingChange(`${colorType.toLowerCase()}Color`, e.target.value)}
                                style={{ marginLeft: '10px', width: '30px', height: '30px', borderRadius: '50%' }}
                            />
                            {settings[`${colorType.toLowerCase()}Color`] !== `#${colorType === 'Font' ? '000000' : '3f51b5'}` && (
                                <IconButton onClick={() => handleSettingChange(`${colorType.toLowerCase()}Color`, `#${colorType === 'Font' ? '000000' : '3f51b5'}`)}>
                                    <ClearIcon style={{ marginLeft: '10px' }} />
                                </IconButton>
                            )}
                        </div>
                    </MenuItem>
                ))}
                <Divider />

                {['google', 'facebook', 'whatsapp'].map((name) => (
                    <MenuItem key={name} disabled={!isSocialLoginEnabled}>
                        <Checkbox
                            checked={checkboxes[name]}
                            onChange={handleCheckboxChange(name)}
                            color="primary"
                        />
                        {name.charAt(0).toUpperCase() + name.slice(1)}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default SocialMediaDropdown;
