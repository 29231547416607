import {
    GET_ALL_TEMPLATE_ACTION,
    SUCCESSFULLY_GET_ALL_TEMPLATE,
    CREATE_TEMPLATE_ACTION,
    SUCCESSFULLY_CREATE_TEMPLATE,
    UPDATE_TEMPLATE_ACTION,
    SUCCESSFULLY_UPDATE_TEMPLATE
} from "../actionTypes/templateActionTypes";

import { ProjectInitialState } from "../initialState/templateState";

export const TemplateReducer = (state = ProjectInitialState, action) => {
    switch (action.type) {
        case "SUCCESSFULLY_GET_ALL_TEMPLATE": {
            return {
                ...state,
                templateData: action.payload,
            };
        }
        case "SUCCESSFULLY_CREATE_TEMPLATE": {
            return { ...state, createTemplate: action.payload };
        }
        case "SUCCESSFULLY_UPDATE_TEMPLATE": {
            return {
                ...state,
                updateTemplate: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};
